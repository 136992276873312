<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceList-container">
    <!-- <div>客服总数：{{pagination.total}} ； 客服总在线数：{{size}}  </div> -->
    <div class="title-main">
      <p> <span></span><span class="normal">客服列表</span></p>
      <div>
        <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="() => { setLevelShow() }">批量设置等级</el-button>
      </div>
    </div>
    <div class="common-Summary-container">
      <div class="Summary-item">
        <div class="item-title">客服总数</div>
        <div class="item-num common-nofinger">{{ pagination.total }}</div>
      </div>
      <!-- <div class="Summary-item">今日</div> -->
      <div class="Summary-item">
        <div class="item-title">客服总在线数</div>
        <!-- { name: '所属公司', type: 'select', modelKey: 'companyId', selOpt: rolesCompany }, -->
        <div @click="() => { collect(
                '',
                '客服总在线数',
                [
                  { name: '客服名称', type: 'input', modelKey: 'nickName' },
                  
                ],
                [
                  { name: '客服名称', colProp: ['nickName'] },
                  { name: '所属公司', colProp: ['companyName'] },
                  { name: '所属部门', colProp: ['departName'] },
                  { name: '当前带店数', colProp: ['takeNum'] },
                  { name: '当前在线数', colProp: ['onlineNum'] },
                ]
              )}" 
          class="item-num">{{ size }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">当日服务时间内总数</div>
        <div class="item-num common-nofinger">{{ todayNum }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">子账号在线数</div>
        <div class="item-num common-nofinger">{{ loginNum }}</div>
      </div>
    </div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>客服员工:</span>
        <el-input
          class="common-screen-input"
          v-model="params.keyword"
          maxLength="50"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属部门:</span>
        <a-cascader class="common-screen-input" v-model="params.departId" :options="selectDepart" :fieldNames="{ label: 'name', value: 'id', children: 'child' }" placeholder="请选择" change-on-select @change="commonFun"/>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => commonFun()">查 询</el-button>
      <el-button class="common-screen-btn" @click="() => clear()" plain>重 置</el-button>
    </div>
    <div class="table-main">
      <el-table class="common-table" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column prop="nickName" label="客服名称">
              <template slot-scope="scope">
                  <span style="color: #1890ff;cursor: pointer;" @click="() => { winOpenFun(`/personal-center?userId=${scope.row.adminId}`) }">{{scope.row.nickName || '--'}}</span>
              </template>
          </el-table-column>
        <el-table-column
          prop="phone"
          label="登录账号"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属公司"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="departName"
          label="所属部门"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="takeNum"
          label="当前带店数"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="serviceLevel"
          label="客服等级"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="onlineNum"
          label="当前在线数"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="createUser"
          label="创建人"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
					<span>{{scope.row.createTime ? formatDateTimer(scope.row.createTime) : '--'}}</span>
				</template>
        </el-table-column>
        <el-table-column label="操作" width="160" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="() => { setLevelShow(scope.row) }">设置客服等级</el-button>
            <!-- <el-button type="text" size="small" @click="() => setTakeShow(scope.row)">查看/设置带店</el-button> -->
            <!-- <el-dropdown trigger="click">
              <span class="el-dropdown-link">设置客服等级</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in levelSelect" :key="index">
                  <div @click="() => { setLevelShow(scope.row, item) }">
                    {{item.serviceLevel}}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      style="text-align: center;margin-bottom: 32px"
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog v-dialogDrag title="设置带店" :append-to-body="true" :visible.sync="centerDialogVisible" width="520px">
      <div class="dialog-content">
        <el-form :model="setForm" :rules="setFormRules" ref="setForm" label-width="100px" class="demo-ruleForm">
          <div class="servDetail-item">
            <div class="item-half"><span class="item-tit">客服名称:</span>{{ setShopData.cusName }}</div>
            <div class="item-half"><span class="item-tit">客服账号: </span>{{ setShopData.cusAccount }}</div>
          </div>
          <div class="servDetail-item">
            <div class="item-half">
            <span class="item-tit">所属部门:</span>{{ setShopData.cusDepart }}</div>
          </div>
          <div class="servDetail-item">
            <div class="item-half">
            <span class="item-tit">带店({{ 0 }}/{{ relationList.length }}):</span>
            <div class="takeStore">
              <div class="takeStore-item down" v-for="(item, i) in relationList" :key="i">{{ item.accountName }}</div>
            </div>
            </div>
          </div>
          <div class="servDetail-item">
            <div class="item-half2">
            <span class="item-tit">带店设置:</span>
            <div class="takeStore">
              <el-cascader class="dialog-input" placeholder="设置带店" :key="test1" :options="shopList" v-model="setShopModel"
                :props="{
                  label: 'name',
                  children: 'subAccountList',
                  value: 'id',
                  multiple: true
                }"
                @change="changeShop" filterable></el-cascader>
            </div>
            </div>
          </div>
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker v-model="setForm.datePicker" class="dialog-input" type="daterange" :clearable="false" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click=" () => { setTake(); }">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="设置客服等级" :append-to-body="true" :visible.sync="levelItemVisible" width="520px">
      <el-form :model="levelItemForm" :rules="levelItemRules" ref="levelItemForm" label-width="120px" class="demo-ruleForm" >
        <el-form-item label="客服名称">
          <span>{{levelItemForm.nickName}}</span>
        </el-form-item>
        <el-form-item label="客服等级" prop="levelId">
          <el-select style="width: 80%" v-model="levelItemForm.levelId" placeholder="请选择" @change="(val) => { changeLevel(val, 'one') }" filterable>
            <el-option v-for="(item, index) in levelSelect" :key="index" :label="item.serviceLevel" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div style="border-left: 4px solid #1890ff">
            <div class="levelForm-text">该等级基本工资: <span style="color: #e83636">{{ levelItemForm.basePay || 0 }}元</span></div>
            <!-- <div class="levelForm-text">该等级提成系数: <span style="color: #e83636">{{ levelItemForm.coefficient || 0 }}</span></div> -->
          </div>
        </el-form-item>
        
      </el-form>
        <!-- <div class="level-text">客服名称: {{ levelItemForm.nickName }}</div>
        <div class="level-text">设置客服等级为: {{ levelItemForm.serviceLevel }}</div>
        <div class="level-text">该等级基本工资: <span style="color: #e83636">{{ levelItemForm.basePay || 0 }}元</span></div>
        <div class="level-text">该等级提成系数: <span style="color: #e83636">{{ levelItemForm.coefficient || 0 }}</span></div> -->
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="levelItemVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click=" () => { setSerLevel('levelItemForm') }">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="批量设置客服等级" :append-to-body="true" :visible.sync="levelVisible" width="520px">
      <el-form :model="levelForm" :rules="levelRules" ref="levelForm" label-width="120px" class="demo-ruleForm" >
        <el-form-item label="客服名称">
          <span>{{levelForm.nickName}}</span>
        </el-form-item>
        <el-form-item label="客服等级" prop="levelId">
          <el-select style="width: 80%" v-model="levelForm.levelId" placeholder="请选择" @change="(val) => { changeLevel(val) }" filterable>
            <el-option v-for="(item, index) in levelSelect" :key="index" :label="item.serviceLevel" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
         <el-form-item>
          <div style="border-left: 4px solid #1890ff">
            <div class="levelForm-text">该等级基本工资: <span style="color: #e83636">{{ levelForm.basePay || 0 }}元</span></div>
            <!-- <div class="levelForm-text">该等级提成系数: <span style="color: #e83636">{{ levelForm.coefficient || 0 }}</span></div> -->
          </div>
        </el-form-item>
        
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="levelVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click=" () => { moreSetSerLevel('levelForm') }">确 定</el-button>
      </span>
    </el-dialog>
     <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="serOnlineSum"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>
<script>
import { serviceList, shopList, setShop, accountRelation, serOnlineSum, serLevelSelect, setSerLevel, moreSetSerLevel } from "../../service/service.js";
import { commonAllRole, commonDepartAll } from "../../service/common.js";
import { Config, tableColumn, DateTransform, formatDateTimer } from "@/utils";
import CommonSumDialog from "../../components/common/commonSumDialog.vue"
import { Cascader } from "ant-design-vue";
export default {
  components: {
    CommonSumDialog,
    aCascader: Cascader
  },
  data() {
    return {
      formatDateTimer,
      multipleSelection: [], // 多选设置
      loading: false,
      tableColumn,
      size: "",
      loginNum: "",
      todayNum: "",
      // BreadcrumbData: [
      //   { title: "首页", isLink: true, url: "/index" },
      //   { title: "客服", isLink: false },
      //   { title: "客服列表", isLink: false }
      // ],
      selectDepart: [], // 部门下拉
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 修改密码员工弹窗
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司id
      userId: this.$sto.get(Config.constants.userInfo).user_id, // 所属公司id
      setForm: { // =设置带店
        datePicker: []
      },
      setFormRules: {
        datePicker: [
          { required: true, message: "请选择服务时间", trigger: "change" }
        ]
      },
      relationList: [],
      params: {
        page: 1,
        pageSize: 10,
        companyId: "",
        keyword: "",
        departId: []
      },
      setShopData: {
        cusName: "",
        cusAccount: "",
        cusDepart: ""
      },
      shopList: [],
      shopListCommit: [],
      setShopModel: [], // 设置带店的数据
      test1: 1,
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      subAccountList: [],
      accountId: "",
      tableData: [],
      // 汇总弹窗所需数据
      commonSumVisible: false,
      serOnlineSum, // 列表接口方法
      levelSelect: [], // 等级下拉
      levelItemVisible: false, // 列表设置等级弹窗
      levelVisible: false, // 批量设置等级弹窗
      levelItemForm: { // 列表设置等级参数
        adminId: '',
        levelId: '',
        updateId: '',
        serviceLevel: '',
        basePay: '',
        coefficient: '',
        nickName: ''
      },
      levelForm: {
        levelId: ''
      },
      levelRules: {
        levelId: [{ required: true, message: "请选择客服等级", trigger: "change" }]
      },
      levelItemRules: {
        levelId: [{ required: true, message: "请选择客服等级", trigger: "change" }]
      },
    };
  },
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
      this.commonDepartAll(this.rolesCompany[0].id);
    }
    this.commonAllRole();
    this.getTableData();
    this.serLevelSelect()
  },
  methods: {
    winOpenFun (url) {
      window.open(url)
    },
    changeLevel(val, type) { // 批量设置等级-切换等级
      if (type == 'one') {
        let levelSelect = [...this.levelSelect]
        levelSelect.map((item) => {
          if (val == item.id) {
            this.levelItemForm.basePay = item.basePay
            this.levelItemForm.coefficient = item.coefficient
          }
        })
      } else {
        let levelSelect = [...this.levelSelect]
        levelSelect.map((item) => {
          if (val == item.id) {
            this.levelForm.basePay = item.basePay
            this.levelForm.coefficient = item.coefficient
          }
        })
      }
    },
    handleSelectionChange(val) { // 表格多选
      this.multipleSelection = val;
    },
    async moreSetSerLevel(formName) { // 批量设置客服等级
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let levelForm = {...this.levelForm}
          let { code } = await moreSetSerLevel(levelForm)
          if (code == 200) {
            this.getTableData()
            this.$message.success("设置成功")
            this.levelVisible = false
          }
        } else {
          return false;
        }
      });
    },
    async setSerLevel(formName) { // 设置客服等级
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let levelItemForm = {...this.levelItemForm}
          let { code } = await setSerLevel(levelItemForm)
          if (code == 200) {
            this.getTableData()
            this.$message.success("设置成功")
            this.levelItemVisible = false
          }
        } else {
          return false;
        }
      });
      
    },
    setLevelShow(row) { // 设置等级弹窗
      if (row) {
        let levelItem = {}
        if (row.serviceLevel || row.serviceLevel == 0) {
          let levelSelect = [...this.levelSelect]
          levelSelect.map((item) => {
            if (row.serviceLevel == item.serviceLevel) {
              levelItem = item
            }
          })
        }
        
        this.levelItemForm = { // 列表设置等级参数
          adminId: row.adminId,
          levelId: levelItem.id || '',
          updateId: this.userId,
          serviceLevel: levelItem.serviceLevel || '',
          basePay: levelItem.basePay || '',
          coefficient: levelItem.coefficient || '',
          nickName: row.nickName
        }
        this.levelItemVisible = true
      } else {
        let multipleSelection = [...this.multipleSelection]
        if (!multipleSelection || !multipleSelection.length) { return this.$message.error("请先选择客服，再进行批量设置") }
        let nickName = []
        let ids = []
        multipleSelection.map((item) => {
          nickName.push(item.nickName)
          ids.push(item.adminId)
        })
        this.levelForm = { // 批量设置等级参数
          levelId: '',
          basePay: '',
          coefficient: '',
          nickName: nickName.join(','),
          ids: ids
        }
        this.levelVisible = true
      }
    },
    async serLevelSelect() { // 客服列表等级下拉
      let { data } = await serLevelSelect({ companyId: this.companyId })
      this.levelSelect = data
    },
    // 数据汇总弹窗
    sumVisibleClose () {
      this.commonSumVisible = false
    },
    collect(type, name, screen, arr) { // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.params.companyId);
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗
    
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.getTableData();
    },
    clear() {
      // 重置
      this.params.companyId = this.rolesCompany[0].id;
      this.params.departId = [];
      this.params.keyword = "";
      this.commonDepartAll(this.rolesCompany[0].id);
      this.getTableData();
    },
    changeShop(e) {
      console.log(e);
      let shopList = JSON.parse(JSON.stringify(this.shopListCommit));
      let shopValue = JSON.parse(JSON.stringify(e));
      shopList.map((shopItem) => {
        // 先把值清空
        if (shopItem.subAccountList) {
          shopItem.subAccountList.map((subAccountItem) => {
            subAccountItem.takeFlag = "0";
          });
        }
      });
      shopValue.map((valueItem) => {
        // 获取选中的值，并更改
        if (valueItem) {
          valueItem.map((nodeItem) => {
            if (nodeItem != undefined) {
              shopList.map((shopItem) => {
                if (shopItem.subAccountList) {
                  shopItem.subAccountList.map((subAccountItem) => {
                    if (subAccountItem.accountId == nodeItem) {
                      subAccountItem.takeFlag = "1";
                    }
                  });
                }
              });
            }
          });
        }
      });
      this.shopListCommit = Object.assign([], shopList);
    },
    async setTakeShow(row) {
      let setShopModel = [];
      this.setShopModel = [];
      this.test1++;
      this.centerDialogVisible = true;
      this.setForm = {
        datePicker: []
      };
      if (this.$refs["setForm"]) this.$refs["setForm"].resetFields();
      const adminId = new Object();
      adminId.adminId = row.adminId;
      let resData = (await shopList(adminId)).data;
      resData.forEach((item) => {
        item.name = item.shopName;
        item.subAccountList.forEach((item2) => {
          item2.name = item2.accountName;
          item2.id = item2.accountId;
        });
      });
      this.shopList = JSON.parse(JSON.stringify(resData));
      this.shopListCommit = JSON.parse(JSON.stringify(resData));

      // 详情赋值
      this.setShopData.cusName = row.nickName;
      this.setShopData.cusAccount = row.phone;
      this.setShopData.cusDepart = row.departName;
      // 获取选中的
      let resData2 = (await accountRelation(adminId)).data;
      this.relationList = JSON.parse(JSON.stringify(resData2));

      resData.map((item) => {
        // 赋值选中的值给控件
        if (item.subAccountList) {
          item.subAccountList.map((subAccountItem) => {
            if (resData2) {
              resData2.map((accountItem) => {
                if (subAccountItem.accountName == accountItem.accountName) {
                  setShopModel.push([undefined, subAccountItem.accountId]);
                }
              });
            }
          });
        }
      });
      this.setShopModel = setShopModel;
    },
    setTake() {
      let shopListCommit = [...this.shopListCommit];
      let setForm = { ...this.setForm };
      this.$refs["setForm"].validate(async (valid) => {
        if (valid) {
          if (setForm.datePicker && setForm.datePicker.length > 1) {
            shopListCommit.map((item) => {
              item.subAccountList.map((subItem) => {
                subItem.startDate = DateTransform(setForm.datePicker[0]);
                subItem.endDate = DateTransform(setForm.datePicker[1]);
              });
            });
          }
          await setShop(this.shopListCommit);
          this.$message.success("设置带店成功");
          this.getTableData();
          this.centerDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async getTableData() {
      // 获取表格数据
      this.loading = true;
      // if (this.params.companyId == null) {
      //   this.params.companyId = "";
      // } else if (this.params.departId == null) {
      //   this.params.departId = "";
      // }
      
      let params = { ...this.params };
      if (params.departId && params.departId.length) {
        params.departId = params.departId[params.departId.length - 1]
      }
      let resData = (await serviceList(params)).data;
      this.pagination.total = resData.total;
      // this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.size = resData.size;
      this.todayNum = resData.todayNum;
      this.loginNum = resData.loginNum;
      this.loading = false;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getTableData();
    },
    companyFun(id) {
      //选择公司筛选
      this.params.companyId = id;
      this.getTableData();
      this.commonDepartAll(id);
    },
    async commonAllRole() {
      // 角色下拉
      let resData = (await commonAllRole()).data;
      this.selectRole = resData;
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    }
  }
};
</script>
<style lang="less" scoped>
.levelForm-text {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  // margin-bottom: 16px;
  text-indent: 16px;
}
.level-text {
  font-size: 16px;
  margin-bottom: 16px;
  text-indent: 40px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #1890ff;
}
.serviceList-container {
  text-align: left;
  .common-table {
    margin: 0;
  }
  .table-main {
    padding: 0 32px 32px;
  }
  .title-main {
    display: flex;
    align-items: center;
    padding: 0 32px;
    box-sizing: border-box;
    height: 72px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    p {
      font-size: 20px;
      color: #666;
      span {
        font-size: 20px;
        color: #666;
        cursor: pointer;
      }
      .normal {
        font-size: 24px;
        color: #101010;
        cursor: auto;
      }
    }
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  .servDetail-item {
    padding: 0 0 16px 24px;
    .takeStore {
      display: inline-block;
      width: 320px;
      vertical-align: top;
      .takeStore-item {
        // 带店在线
        display: inline-block;
        padding: 0 10px;
        height: 24px;
        color: #fff;
        line-height: 24px;
        background: #1890ff;
        border-radius: 12px;
        font-size: 12px;
        margin: 0 16px 8px 0;
      }
      .down {
        // 带店离线
        background: #c0c0c0;
      }
    }
    .item-half {
      width: 49%;
      display: inline-block;
      .item-tit {
        width: 31%;
      }
    }
    .item-half2{
      // width: 49%;
      // display: inline-block;
    }
    span {
      display: inline-block;
      width: 76px;
      line-height: 28px;
    }
  }
}
.breadcrumb-container {
  margin-left: 0;
}
.common-screen-container{
  padding: 16px 0 0 32px;
  box-sizing: border-box;
}
.dialog-input {
  width: 320px !important;
}
/deep/ .el-cascader__tags .el-tag .el-icon-close {
  display: none !important;
}
</style>
